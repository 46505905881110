// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useContext } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './loginPage';
import HomePage from './homePage';
import RedirectPage from './pages/welcome';
import './App.css';
import config from './config.json';

import { AuthContext, AuthProvider, IAuthContext, TAuthConfig } from "react-oauth2-code-pkce"

const authConfig: TAuthConfig = {
  clientId: config.clientId,
  authorizationEndpoint: `${config.cognitoDomain}/login`,
  tokenEndpoint: `${config.cognitoDomain}/oauth2/token`,
  redirectUri: `${window.location.protocol}//${window.location.host}`,
  scope: 'email+openid+profile',
  //onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) => event.logIn(undefined, undefined, "popup"),
}

function LoginInfo(): JSX.Element {
  const { tokenData, token, logOut, idToken, error, logIn }: IAuthContext = useContext(AuthContext)

  if (error) {
    return (
      <>
        <div style={{ color: 'red' }}>An error occurred during authentication: {error}</div>
        <button type='button' onClick={() => logOut()}>
          Log out
        </button>
      </>
    )
  }

  return (
    <>
      {token ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            color: 'grey',
            fontFamily: 'sans-serif',
          }}
        >
          <div
            style={{
              padding: '10px',
              margin: '10px',
              border: '1px solid white',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p>Welcome, John Doe!</p>

            <button type='button' style={{ width: '100px' }} onClick={() => logOut()}>
              Log out
            </button>

            <p>Use this token to authenticate yourself</p>
            <pre
              style={{
                width: '400px',
                margin: '10px',
                padding: '5px',
                border: 'black 2px solid',
                wordBreak: 'break-all',
                whiteSpace: 'break-spaces',
              }}
            >
              {token}
            </pre>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            color: 'grey',
            fontFamily: 'sans-serif',
          }}
        >
          <div
            style={{
              padding: '10px',
              margin: '10px',
              border: '1px solid white',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p>Please login to continue</p>

            <button type='button' style={{ width: '100px' }} onClick={() => logIn()}>
              Log in
            </button>
          </div>
        </div>
      )}
    </>
  )
}

const App = () => {
  const isAuthenticated = () => {
    const accessToken = sessionStorage.getItem('ROCP_token');
    return !!accessToken;
  };

  return (
    <AuthProvider authConfig={authConfig}>
        <LoginInfo/>
    </AuthProvider>
  );
};

export default App;
